<template>
    <!-- <section class="contact-us" >
        <div class="contact-us-container" >
            <div class="contact-us-content">
                <h1 class="contact-us-content_title">{{$t('contact.title')}}</h1>
                <p class="contact-us-content_subtitle">{{$t('contact.subtitle')}}</p>
                <buttonCallPopup class="btn-main" type="modal.write-us"></buttonCallPopup>
            </div>
            <div class="contact-us-icon">
                <img class="contact-us-icon_img" :src="require(`@/assets/img/scenes/contact-us.svg`)" alt="">
            </div>
        </div>
    </section> 
    @import '@/pages/blockchain/sections/contactUs.sass'
    -->
    <section class="contactus-section">
        <div class="contactus-section-wrapper-absolute">
            <div class="contactus-section-wrapper-relative"> 
                <div class="contactus-section-wrapper-relative-container" >
                    <div class="contactus-section-wrapper-relative-content">
                        <h3 class="contactus-section-wrapper-relative-content_title">{{$t('contact.title')}}</h3>
                        <p class="contactus-section-wrapper-relative-content_subtitle">{{$t(subtitle)}}</p>
                        <buttonCallPopup class="btn-main" type="modal.write-us"></buttonCallPopup>
                    </div>
                    <div class="contactus-section-wrapper-relative-icon">
                        <img class="contactus-section-wrapper-relative-icon_img" :src="require(`@/assets/img/scenes/contact-us.svg`)" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        subtitle: {
            type: String,
            required: true,
            default() {
                return 'contact.subtitle'
            }
        }
    }
}
</script>

<style lang="sass">
.contactus-section
    position: relative 
    display: flex 
    align-items: center 
    justify-content: center
    padding-top: 280px
    background: #fff
    @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
        padding-top: 290px 
        padding-bottom: 0px
    @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
        padding-top: 220px
    &-wrapper-absolute
        padding: 10%
        position: absolute
        flex-direction: column
        z-index: 12
        display: flex 
        justify-content: center 
        align-items: center
        @media screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
            padding: 4%
        @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
            padding: 16px
    &-wrapper-relative
        display: flex
        flex-direction: column
        position: relative 
        display: flex 
        background: #fff
        border-radius: 10px
        @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
            align-items: center

        &-container
            height: fit-content
            display: flex 
            align-self: center
            text-shadow: #fff 1px 1px 0, #fff -1px -1px 0, #fff -1px 1px 0, #fff 1px -1px 0, #fff 1.5px 1.5px 0, #fff -1.5px -1.5px 0, #fff -1.5px 1.5px 0, #fff 1.5px -1.5px 0
            display: flex
            align-items: center 
            background: #fff
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06)
            border-radius: 10px
            max-width: 1138px
            padding: 64px 80px 64px
            z-index: 999
            @media screen and (max-width: 1400px), (min-device-width: 1150px) and (max-device-width: 1400px)
                justify-content: flex-start
            @media screen and (max-width: 1150px), (min-device-width: 768px) and (max-device-width: 1150px)
            @media screen and (max-width: 1150px), (min-device-width: 768px) and (max-device-width: 1150px)
                padding: 40px
            @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
                padding: 20px
            @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
                flex-direction: column-reverse
                align-items: center
                padding: 24px 18px

        &-content 
            max-width: 60%
            min-width: 440px
            display: flex 
            flex-direction: column
            justify-content: center
            .btn-main
                width: 260px
                display: flex 
                align-self: flex-start
            @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
                
            @media screen and (max-width: 585px), (min-device-width: 100px) and (max-device-width: 585px)
                max-width: 100%
                min-width: 80px
                align-items: center
                .btn-main
                    max-width: 240px
                    display: flex
                    align-self: center
            &_title 
                font-size: 46px
                line-height: 56px
                margin-bottom: 24px
                color: #04133B
                @media screen and (max-width: 1150px), (min-device-width: 768px) and (max-device-width: 1090px)
                    font-size: 38px
                @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
                    font-size: 36px
                    line-height: 44px
                    margin-bottom: 16px
                @media screen and (max-width: 570px), (min-device-width: 100px) and (max-device-width: 570px)
                    text-align: center
                @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
                    font-size: 24px 
                    line-height: 20px 
                    margin-bottom: 11px
                    text-align: center
            &_subtitle
                font-size: 24px
                line-height: 36px
                padding-bottom: 24px
                color: #687189
                @media screen and (max-width: 1090px), (min-device-width: 768px) and (max-device-width: 1090px)
                    font-size: 20px
                @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
                    font-size: 16px 
                    line-height: 23px
                    padding-bottom: 16px
                    width: 70%
                @media screen and (max-width: 585px), (min-device-width: 100px) and (max-device-width: 585px)
                    display: inline-block
                    width: 100%
                    text-align: center
                @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
                    font-size: 16px 
                    line-height: 24px

        &-icon 
            position: relative
            display: flex 
            align-items: center 
            justify-content: center
            @media screen and (max-width: 1400px), (min-device-width: 1090px) and (max-device-width: 1400px)
                position: absolute
                right: 30px
                z-index: -1
            @media screen and (max-width: 1090px), (min-device-width: 768px) and (max-device-width: 1090px)
                height: 250px
            @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
                height: 200px
            @media screen and (max-width: 585px), (min-device-width: 100px) and (max-device-width: 585px)
                display: none
                position: relative
                display: contents
                height: 100%
                padding-left: 30px
                img 
                    display: none
                    height: 76px

</style>