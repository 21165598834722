<template>
    <div class="floating-special-wrapper">
        <section class="floating-special">
            <h3 class="floating-special_title" v-html="$t('custom.floating.spec-title')"></h3>
            <p class="floating-special_subtitle" v-html="$t('custom.floating.spec-subtitle')"></p>
            <img class="floating-special_scene" :src="require(`@/assets/img/scenes/contracts-7.svg`)" alt="">
            <div class="floating-special-background">
                <img class="floating-special-background_network-one" :src="require(`@/assets/img/scenes/development-installation-network.svg`)" alt="">
                <img class="floating-special-background_network-two" :src="require(`@/assets/img/scenes/development-installation-network.svg`)" alt="">
            </div>
        </section>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="sass" scoped>
.floating-special
    position: relative
    display: flex
    bottom: -46px
    flex-direction: column 
    align-items: center 
    justify-content: center
    background: #F4F7FF
    padding-top: 60px
    padding-bottom: 80px
    margin-top: 60px
    @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
        margin-top: 0px
    &_title 
        text-align: center
        font-size: 24px
        line-height: 32px
        max-width: 440px
        @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
            padding: 0 30px
        @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
            max-width: 320px 
            line-height: 28px
            padding: 0 16px
    &_subtitle
        margin-top: 26px
        text-align: center
        font-size: 18px
        line-height: 30px
        max-width: 410px
        @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
            font-size: 16px 
            line-height: 24px
            margin-top: 16px
            padding: 0 12px
        @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
            margin-top: 12px
    &_scene
        width: 675px
        height: 447px
        margin-top: 48px
        z-index: 2
        @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
            height: 200px

    &-background
        position: absolute
        display: flex 
        width: 100%
        min-height: 600px
        justify-content: space-between
        z-index: 1
        bottom: 0
        @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
            bottom: 0
            left: 0

        &_network-one
            position: absolute
            bottom: 10%
            left: 0
            opacity: 0.5
            @media screen and (max-width: 800px), (max-device-width: 800px)
                max-width: 40%
                bottom: 0
            
        &_network-two
            position: absolute
            max-width: 100%
            transform: scaleX(-1)
            right: 0
            opacity: 0.5
            bottom: 10%
            @media screen and (max-width: 800px), (max-device-width: 800px)
                max-width: 40%
                bottom: 0



.floating-special-wrapper
    background: #FFF
    padding-top: 20px
    position: relative
    &:before
        content: ''
        position: absolute 
        top: 0
        left: 0
        width: 100% 
        height: 100%
        background: #F4F7FF
        border-radius: 50% 50% 0 0/ 100% 100% 0 0
        transform: scaleX(4.4)
        z-index: 0
</style>