<template>
    <div class="floating-element">
        <div class="floating-element_description">
            <div class="floating-element_description-title">
                    <div class="floating-element_description-title_number"
                        v-if="!!numberMark">{{numberMark}}</div>
                    <div class="floating-element_description-title_text">{{$t(title)}}</div>
            </div>
            <div :class="specialClass">
                <div class="floating-element_description_item">
                    <div v-if="!!listFirst && !checkmarkOff"
                        class="floating-element_description_item-container"
                    >
                        <svg-icon name="checkmark-1"/>
                    </div>
                    <div class="floating-element_description_item_def" v-html="$t(listFirst)"></div>
                </div>
                <div class="floating-element_description_item">
                    <div v-if="!!listSecond"
                        class="floating-element_description_item-container"
                    >
                        <svg-icon name="checkmark-1"/>
                    </div>
                    <div class="floating-element_description_item_def" v-html="$t(listSecond)"></div>
                </div>
            </div>
        </div>
        <div class="floating-element_icon">
            <img class="floating-element_icon--img" :src="require(`@/assets/img/scenes/${icon}.svg`)" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        listFirst: {
            type: String,
            required: false
        },
        listSecond: {
            type: String,
            required: false
        },
        checkmarkOff: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        },
        numberMark: {
            type: Number,
            required: false
        },
        specialClass: {
            type: String,
            required: false,
            default() {
                return ''
            }
        },
    }
}
</script>

<style lang="sass" scoped>
.floating-element
    display: flex
    align-items: flex-start 
    justify-content: space-around
    height: 500px
    @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
        margin: 0px 0px 0px 0px
        align-items: center 
        justify-content: center
        height: 360px
    @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
        height: fit-content



    &:nth-child(2n-1)
        .floating-element_description
            position: relative 
            top: -22px
            padding-right: 90px
            @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
                padding-right: 50px
            @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
                padding: 0
        @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
            padding: 0px 
            flex-direction: column

    &:nth-child(2n)
        flex-direction: row-reverse
        .floating-element_description
            position: relative
            top: -22px
            margin-left: 10%
            padding-left: 90px
            @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
                padding-left: 50px
                margin: 0

            @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
                margin-left: 0
                padding-left: 0
        @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
            padding: 0px 
            flex-direction: column

        

    &:last-child 
        margin-top: 0px
        @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        @media screen and (max-width: 416px), (min-device-width: 100px) and (max-device-width: 416px)

    &_icon 
        padding-left: 40px
        margin-bottom: 40px
        @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
            padding: 0
            height: 300px
        @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
            display: none
        
        &--img 
            width: 400px 
            height: 400px
            object-fit: fill
            @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
                display: none


    &_description 
        width: 600px

        @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
            width: 100%

        @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
            padding: 0

        &-title 
            font-size: 24px
            font-weight: bold
            display: flex 
            @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
                justify-content: flex-start
            @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
                flex-direction: column
                align-items: center


            &_text 
                display: flex 
                align-items: center
                justify-content: center
                font-size: 24px 
                line-height: 32px
                margin-left: 24px
                @media screen and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
                    text-align: center
                @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
                    margin-left: 0
            &_number
                min-width: 50px 
                height: 50px
                border-radius: 4px
                color: #fff
                display: flex
                align-items: center 
                justify-content: center
                background: linear-gradient(184.95deg, #73A9FF 5.81%, #415DF8 78.76%), linear-gradient(57.32deg, #ED6B6B 18.61%, #FFA275 201.33%), #4071F4
                @media screen and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
                    width: 40px
                    margin-bottom: 10px
                
        &_item
            display: flex
            align-items: flex-start
            font-size: 18px
            list-style: decimal-leading-zero
            padding: 12px 0
            line-height: 30px
            @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
                font-size: 16px
                line-height: 24px
                font-weight: 300
                padding: 12px 0
            @media screen and (max-width: 416px), (min-device-width: 270px) and (max-device-width: 416px)
                font-size: 14px 
                line-height: 22px
            @media screen and (max-width: 270px), (min-device-width: 100px) and (max-device-width: 270px)
                font-size: 12px
            &_def
                padding: 0 15px 0 0
                @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
                    padding: 0

            &-container
                display: flex 
                align-items: center 
                justify-content: center
                padding: 5px 10px 0 0

            &::marker
                color: blue

            ::v-deep .spec-list
                font-weight: bold
                list-style: inside

                ::marker 
                    color: #4071F4

.special-element 
    @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        display: flex
        flex-direction: column



.svg-icon--checkmark-1
    width: 18px
    height: 18px
</style>