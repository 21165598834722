<template>
    <div class="custom-page">
        <section-head title="sectionhead.title-custom" subtitle="sectionhead.subtitle-custom"/>
        <section-options style="margin-top: 100px" :icons="options.icons" :description="options.description"/>
        <section-contact-us style="padding-top: 210px" subtitle="contact.subtitle-custom" />
        <section-support />
        <section-float 
            title="custom.floating.headtext" 
            isWhiteBorderTop="yes" 
            class="floating-section-spec"
        >
            <element-float 
                v-for="(element, index) in 6" 
                :key="index"
                :title="`custom.floating.title-${index}`"
                :listFirst="`custom.floating.el-${index}`"
                :icon="`custom-${index+1}`"
                :numberMark="index+1"
                :checkmarkOff="true"
            />
            <div class="border-vl">
                <svg width="51" height="3078" viewBox="0 0 51 3078" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25.002" cy="23" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="23" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="520" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="520" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="1017" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="1017" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="2014" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="2014" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="1514" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="1514" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="2514" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="2514" r="4" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="61" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="558" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="1055" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="1552" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="2052" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="2552" width="2" height="460" fill="#4071F4"/>
                    <rect x="0.00195312" y="3028" width="50" height="50" rx="4" fill="#4071F4"/>
                    <rect x="0.00195312" y="3028" width="50" height="50" rx="4" fill="url(#paint0_linear)"/>
                    <rect x="0.00195312" y="3028" width="50" height="50" rx="4" fill="url(#paint1_linear)"/>
                    <path d="M18.982 3042.4H32.394V3045.06C31.3673 3046.22 30.434 3047.42 29.594 3048.67C28.754 3049.9 28.0166 3051.22 27.382 3052.62C26.766 3054 26.262 3055.47 25.87 3057.02C25.478 3058.57 25.2166 3060.23 25.086 3062H21.586C21.698 3060.45 21.9593 3058.92 22.37 3057.41C22.7993 3055.9 23.3406 3054.43 23.994 3053.01C24.666 3051.59 25.4406 3050.23 26.318 3048.92C27.1953 3047.62 28.138 3046.39 29.146 3045.26H18.982V3042.4Z" fill="white"/>
                    <defs>
                    <linearGradient id="paint0_linear" x1="0.00198923" y1="3054.19" x2="106.252" y2="2986.04" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ED6B6B"/>
                    <stop offset="1" stop-color="#FFA275"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="25.9784" y1="3029.08" x2="22.5732" y2="3068.42" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#73A9FF"/>
                    <stop offset="1" stop-color="#415DF8"/>
                    </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="border-number-container">
                <div class="border-number-wrapper">
                    <div class="border-number">
                        <div class="border-number-itself">7</div>
                    </div>
                </div>
            </div>

        </section-float>
        <element-float-spec />
        <section-footer paragraph="sectionfooter.paragraph-custom" />
    </div>
</template>

<script>
import sectionHead from '@/pages/asections/sectionHead.vue';
import optionsSection from '@/pages/asections/optionsSection.vue';
import contactUs from '@/pages/blockchain/sections/contactUs.vue';
import customSupport from '@/pages/custom/sections/customSupport.vue';
import floatingSection from '@/pages/asections/floatingSection.vue';
import elementFloat from '@/pages/custom/sections/elementFloat.vue';
import elementFloatSpecial from '@/pages/custom/sections/elementFloatSpecial.vue'
import sectionFooter from '@/pages/asections/sectionFooter.vue'
import { getAlternatesForCurrentLanguage, getCanonicals } from '@/helpers/locale-helper'

export default {
    metaInfo() {
      return {
        title: this.$t('meta_title-customdev'),
        keywords: this.$t('meta_keywords'),
        description: this.$t('meta_customdev-description'),
        link: getAlternatesForCurrentLanguage().concat(getCanonicals())
      }
    },
    data: () => ({
         options: {
            description: {
                headtext: 'custom.desc.headtext',
                maintext: 'custom.desc.maintext',
                bottomtext: 'custom.desc.bottomtext',
            },

            icons: [{
                title: 'icontitle.mobile-apps',
                name: 'options-mobile-apps'
            },
            {
                title: 'icontitle.real-time-app',
                name: 'options-real-time-app'
            },
            {
                title: 'icontitle.crm',
                name: 'options-crm'
            },
            {
                title: 'icontitle.online-games',
                name: 'options-online-games'
            },
            {
                title: 'icontitle.landing',
                name: 'options-landing'
            },
            {
                title: 'icontitle.sass',
                name: 'options-saas'
            }],
         }
    }),
    components: {
        'section-options': optionsSection,
        'section-head': sectionHead,
        'section-contact-us': contactUs,
        'section-support': customSupport,
        'section-float': floatingSection,
        'element-float': elementFloat,
        'element-float-spec': elementFloatSpecial,
        'section-footer': sectionFooter
    }
}
</script>

<style lang="sass" scoped>
.floating-section-spec
    padding-top: 240px 
    @media screen and (max-width: 768px), (max-device-width: 768px)
        padding-top: 180px
    @media screen and (max-width: 320px), (max-device-width: 320px)
        padding-top: 120px
        padding-bottom: 60px

.custom-page 
    overflow: hidden

.border-vl
    position: absolute
    top: 430px
    left: 49%
    height: 120px
    z-index: 999
    @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        display: none

    border-radius: 90% 90% 0 0

.border-number
    position: absolute
    height: 120px
    z-index: 999
    display: none
    width: 50px 
    height: 50px
    color: #fff
    border-radius: 4px
    align-items: center 
    justify-content: center
    background: linear-gradient(184.95deg, #73A9FF 5.81%, #415DF8 78.76%), linear-gradient(57.32deg, #ED6B6B 18.61%, #FFA275 201.33%), #4071F4
    @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        display: flex
        left: 40%
        top: 10px
        left: -25px
    @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
        top: 60px
    &-container
        display: flex 
        align-items: center 
        justify-content: center 
    &-itself 
        font-size: 28px 
        color: #fff
    &-wrapper 
        position: relative
</style>